import React from 'react'

import Button from '../../components/button'
import Card from '../../components/card'
import ComplexTable from '../../components/ComplexTable'
import DashboardLayout from '../../components/dashboard/DashboardLayout';
import Icon from '../../components/icon'
import Page from '../../components/page';
import PageTitle from '../../components/pageTitle'

// Mock data
import { data } from '../../store/mock-reports'

const columns = [
  {
    Header: 'Report Name',
    accessor: 'name',
    sortable: false,
    Cell: props => <span className="row-report">{props.value}</span>
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    sortable: false,
    Cell: props => <span className='row-actions'><button className="button button--secondary button__no-icon button--no-outline">Load</button> <button className="button button--no-outline button--is-disabled button--delete" disabled={true}>Delete</button></span>
  }
]

const Research = () => (
<Page>
  <DashboardLayout>
    <div className="container">
      <div className="container--main">
        <PageTitle title="Research" />
      </div>
      <aside className="container--aside container--right">
        <Button theme="secondary" to="/research/report">
          <Icon icon="research" modifier="plus" />
          <span>Create New</span>
        </Button>
      </aside>
    </div>
    <div>
      <Card>
        <ComplexTable
          data={data}
          columns={columns}
          showPagination={false}
          resizable={false}
        />
      </Card>
    </div>
  </DashboardLayout>
</Page>
)

export default Research
